<template lang="pug">
head-meta(title-page="Вход" )
the-login
contacts-line
</template>

<script>
import TheLogin from '../components/login/TheLogin'
import { getUser } from '../assets/scripts/API'
import HeadMeta from '../components/_layout/HeadMeta'
import ContactsLine from '../components/_layout/TheContactsLine'

export default {
  components: { ContactsLine, HeadMeta, TheLogin },
  name: 'LoginPage',
  async beforeRouteEnter (to, from, next) {
    const user = await getUser()
    if (user.is_auth) return next({ name: 'UserOrders' })
    next()
  }
}
</script>
