<template lang="pug">
article.login
  .login__cont.cont
    h1.login__title.title Вход
    p.login__text.text-big
      | Войдите в личный кабинет, чтобы увидеть заказы,
      br
      | ранее добавленные товары в&nbsp;корзине и сохранять любимые товары
    form.login__form(@submit.prevent="sendForm")
      input.login__input.text.input(type="email", placeholder="Электронная почта*", v-model="email", required)
      input.login__input.text.input(type="password", placeholder="Пароль*", v-model="password", required)
      span.login__invalid-password.text(v-if="error") Неверная почта или пароль
      button.login__button.button-white Войти
      ul.login__list
        li.login__item
          router-link.login__link.link-dot_white(:to="{ name: 'Registration' }") Регистрация
        li.login__item
          router-link.login__link.link-dot_white(:to= "{ name: 'ForgotPassword' }") Забыли пароль?
</template>

<script>
import '@/assets/styles/components/login.sass'
import { login } from '../../assets/scripts/API'

export default {
  name: 'TheLogin',
  data () {
    return {
      error: false,
      email: '',
      password: '',
      isDisabled: true,
      isValidPassword: true,
      isEmptyForm: false
    }
  },
  methods: {
    sendForm () {
      this.error = false
      login(this.email, this.password).then(async res => {
        if (res.status === 'error') this.error = true
        else await this.$router.push({ name: 'UserOrders' })
      })
    }
  }
}
</script>
